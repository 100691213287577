@import '~bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');

body {
  font-family: 'Ringside Regular SSm';
}

.App-logo {
  width: 30px;
  height: 30px;
  object-fit: contain;
  margin-left: 10px;
  margin-right: 10px;
}
.App-logo-text {
  width: 30px;
  height: 24px;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #3e3f42;
}
.App-header-divider {
  width: 1px;
  height: 38px;
  background-color: #eaedf3;
  margin-left: 40px;
  margin-right: 40px;
}
.App-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-color: #fff;
}

.menu-wrapper {
  display: flex;
}

.App-tab-link, .menu-link-dropdown {
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: normal;
  flex: 1;
  display: block;
  text-align: center;
  padding: 8px 0;
  transition: border-color 0.3s linear;
  border-bottom: 3px solid transparent;
  min-width: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-tab-link.selected {
  border-bottom: 3px solid #3e75c8;
}

.App-tab-link a.menu-link, .App-tab-link a.menu-link-dropdown {
  color: #9ea0a5;
  transition: color 0.3s linear;
}

.App-tab-link a.menu-link:hover, .App-tab-link a.menu-link-dropdown:hover, .App-tab-link a.dropdown-toggle.nav-link:hover {
  color: #3e75c8;
  text-decoration: none;
}

.App-tab-link.selected a.menu-link, .App-tab-link.selected a.dropdown-toggle, .App-tab-link.selected a.dropdown-toggle.nav-link {
  color: #3e75c8;
}

.menu-link-dropdown.active, .menu-link-dropdown:focus, .App-tab-link a.menu-link-dropdown.selected {
  color: #3e75c8;
  text-decoration: none;
  background-color: #eaedf3;
}

.user-area {
  margin-bottom: 3px;
}

.chip-input-container .chip-input {
  border: 1px solid #454b60 !important;
}

.App-email {
  height: 24px;
  font-size: 13px;
  line-height: 1.85;
  letter-spacing: normal;
  color: #37373c;
  text-transform: lowercase;
  padding-right: 5px;
}

.App-body {
  min-height: 600px;
  background-color: #fafafa;
}

.App-footer {
  text-align: right;
  padding-top: 2px;
  font-size: 12px;
  font-weight: bold;
  color: #9ea0a5;
}

@media (min-width: 1200px) {
  .swipeable-container {
    max-width: 1344px;
  }
}
@media (min-width: 992px) {
  .swipeable-container {
      max-width: 1344px;
  }
}

.custom-width-modal {
  width: 400px;
}