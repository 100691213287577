@import './Fonts.css';

body {
  margin: 0;
  padding: 0;
  font-family: 'Ringside Regular SSm';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.selector-card-preview-type {
  cursor: pointer;
}

.selector-card-preview-type:hover {
  opacity: 0.7;
}

.pointer {
  cursor: pointer;
}
